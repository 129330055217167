import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { rem, rgba } from 'polished';

import { Headline } from './Typography';

import { fluidImageType } from '../helpers/prop-types';
import { colors, fontSizes } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';

/** Wrapper um die Komponente herum */
const Wrapper = styled.div`
    ${mq.smallOnly`
        margin-bottom: 1.75em;
        &:last-child {
            margin-bottom: 0;
        }
    `};
`;

/** Bild */
const Img = styled(GatsbyImage)`
    box-shadow: 0px 0px 4px ${rgba(colors.black, 0.15)};
    margin-bottom: ${rem(10)};
`;

/** Name */
const Name = styled(Headline)`
    ${fontSizes.h5}
    font-weight: normal;
    margin-bottom: ${rem(5)};
`;

/** Title */
const Title = styled.strong``;

/**
 * Erzeugt eine PersonBox
 * @param {string} props.name Name der Person
 * @param {string} props.title Titel der Person
 * @param {fluidImageType} props.image Bild
 */
const PersonBox = ({ name, title, image }) => (
    <Wrapper>
        <Img fluid={image.fluid} />
        <Name level="h3">{name}</Name>
        <Title>{title}</Title>
    </Wrapper>
);

PersonBox.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: fluidImageType.isRequired,
};

export default PersonBox;

/**
 * GraphQL Fragment für GatsbyImage
 * @type {GraphQL}
 */
// eslint-disable-next-line no-undef
export const query = graphql`
    fragment PersonBoxFluid on File {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;
